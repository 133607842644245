import Vue from "vue";
import Router from "vue-router";
import OverView from "./views/OverView.vue";
import NotFound from "./views/NotFound.vue";
import store from "./store";

Vue.use(Router);

function ifAuthenticated(_to, _from, next) {
  if (store.getters["auth/needAuthentication"]) {
    window.location.replace("/login/");
  } else {
    next();
  }
}

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "overview",
      component: OverView,
      meta: { title: "Overview" },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/annotate/:batchSubId/:page",
      name: "annotate",
      component: () =>
        import(
          /* webpackChunkName: "batch-submission" */ "./views/BatchSubmission.vue"
        ),
      meta: { title: "Annotation Tool" },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/segment/:batchSubId/:page",
      name: "segment",
      component: () =>
        import(
          /* webpackChunkName: "batch-submission" */ "./views/BatchSubmission.vue"
        ),
      meta: { title: "Segmentation Tool" },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/review/:batchSubId/:page",
      name: "review",
      component: () =>
        import(
          /* webpackChunkName: "batch-submission" */ "./views/BatchSubmission.vue"
        ),
      meta: { title: "Review Annotation Tool" },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/review-submitted/:batchSubId/:page",
      name: "review-submitted",
      component: () =>
        import(
          /* webpackChunkName: "batch-submission" */ "./views/BatchSubmission.vue"
        ),
      meta: { title: "Review Annotation Tool" },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/new-batch",
      name: "new-batch",
      component: () =>
        import(/* webpackChunkName: "admin-views" */ "./views/NewBatch.vue"),
      meta: { title: "New Batch" },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/bulk-create-users",
      name: "bulk-create-users",
      component: () =>
        import(
          /* webpackChunkName:  "admin-views" */ "./views/BulkCreateUsers.vue"
        ),
      meta: { title: "Bulk Create Users" },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/lookup-annotation",
      name: "lookup-annotation",
      component: () =>
        import(
          /* webpackChunkName:  "admin-views" */ "./views/LookupAnnotation.vue"
        ),
      meta: { title: "Lookup annotations" },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/data-import",
      name: "data-import",
      component: () =>
        import(/* webpackChunkName:  "admin-views" */ "./views/DataImport.vue"),
      meta: { title: "Data import" },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/time-report/:selectedPropBatch?/:selectedPropUser?/:selectedPropImage?",
      name: "time-report",
      props: (route) => {
        return Object.fromEntries(
          Object.entries(route.params)
            .filter(([k, v]) => !isNaN(v))
            .map(([k, v]) => [k, Number(v)])
        );
      },
      component: () =>
        import(/* webpackChunkName: "admin-views" */ "./views/TimeReport.vue"),
      meta: { title: "Time Report" },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/share/:batchId/:imageId",
      name: "share",
      component: () =>
        import(
          /* webpackChunkName: "batch-submission" */ "./views/ShareableUrl.vue"
        ),
      meta: { title: "Shareable URL" },
      beforeEnter: ifAuthenticated,
    },
    { path: "*", component: NotFound, meta: { title: "Page not found" } },
  ],
});
